
"client"
import { useEffect } from 'react';
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'
import { useRouter } from 'next/router'
import NoSsr from '../NoSsr'



export default  function Profile() {   
    const { address, isConnected } = useAccount()
    const {push} = useRouter();
    const { disconnect } = useDisconnect()
    const account = useAccount({
    onDisconnect() {
        push('/')
    },
    })
    const { open, close } = useWeb3Modal()    

    return (
        <>
        <NoSsr>
            <div className="d-flex justify-content-center align-items-center vh-100">
                
                <button
                onClick={address? disconnect:() => open()}
                className="js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className={address? "h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-green dark:fill-green":"h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-red dark:fill-red"}

                >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                </svg>
                </button>
            
            </div>
            </NoSsr>
        </>
        )
}